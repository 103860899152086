.numeric-rating-scale-radio-button {
  --primary-color: var(--color-orange);
  --arrow-offset: 1.375rem;
  --arrow-width: 0.625rem;
  --arrow-height: 0.3125rem;

  position: relative;
  margin-top: var(--arrow-offset);
}

.numeric-rating-scale-radio-button--error {
  --input-border-color: var(--color-red);
}

/* Anchor label arrows */
.numeric-rating-scale-radio-button--show-indicator-arrow::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(calc(-1 * var(--arrow-offset)));
  content: "";
  display: block;
  width: var(--arrow-width);
  height: var(--arrow-height);
  border-left: var(--arrow-height) solid transparent;
  border-right: var(--arrow-height) solid transparent;
  border-top: var(--arrow-height) solid var(--color-black);
}
