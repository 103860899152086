.numeric-rating-scale {
  --nrs-radio-button-size: 1.5625em; /* Taken from the size of the viedoc-radio input element */
  --nrs-side-padding: 0.25rem;
  --nrs-anchor-label-max-width: 9.375rem;

  display: grid;
  grid-template-columns: minmax(min-content, var(--nrs-anchor-label-max-width)) 1fr minmax(min-content, var(--nrs-anchor-label-max-width));
  grid-template-areas:
    'label-start . label-end'
    'choices choices choices';
  gap: 0.625rem 0;
  max-width: 37.5rem;
  width: 100%;
  margin: 0 auto;
}

.numeric-rating-scale__choices {
  grid-area: choices;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, var(--nrs-radio-button-size));
  justify-content: space-between;
  justify-items: center;
  padding: 0 var(--nrs-side-padding);
}

.numeric-rating-scale__anchor-label-start,
.numeric-rating-scale__anchor-label-end {
  display: inline-flex;
  justify-content: center;
  align-items: end;
  width: fit-content;
  min-width: calc(var(--nrs-radio-button-size) + var(--nrs-side-padding) * 2);
}

.numeric-rating-scale__anchor-label-start {
  grid-area: label-start;
  text-align: start;
}

.numeric-rating-scale__anchor-label-end {
  grid-area: label-end;
  justify-self: end;
  text-align: end;
}
